/* styles.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Your custom styles */
/* ... */

:root {
  --primary: #1a0044;
  --yellow: #e2e201;
  --secondary: #00386A
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

main {
  box-sizing: border-box;
  color: #e2e201;
}

.delated-flights-table td {
  position: relative;
  padding-left: 10px;
  /* Adjust this value to control the position of the vertical line */
}

.delayed-flights-table td:not(:first-child,:last-child)::before {
  content: "";
  position: absolute;
  left: 0;
  top: 10%;
  /* Adjust this value to adjust the vertical line's position */
  height: 80%;
  /* Adjust this value to control the line's height */
  width: 1px;
  /* Adjust this value to control the line's width */
  background-color: #E6E7EC;
  /* Adjust this color as needed */
}

/* Update the CSS to change text color to black when the background is yellow */
.flash {
  animation: flash-animation 3s;
  /* Total duration of 3 seconds (1s animation + 2s hold) */
  background-color: yellow;
  /* Initial background color */
  color: black;
  /* Initial text color */
}

@keyframes flash-animation {
  0% {
    background-color: transparent;
    color: #1a0044;
  }

  33.33% {
    background-color: yellow;
    /* Change the background color as needed */
    color: #1a0044;
    /* Change the text color to black */
  }

  100% {
    background-color: yellow;
    /* Final background color */
    color: #1a0044;
    /* Final text color */
  }
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

/* styles.css or your global stylesheet */
.react-datepicker-wrapper {
  @apply w-full;
  /* Full width */
}

.react-datepicker__input-container {
  @apply w-full;
  /* Full width */
}
